

// AddProduct.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import imageCompression from 'browser-image-compression';

const AddProduct = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false); // State to track submission status

  const [productData, setProductData] = useState({
    name: "",
    subcategory: "",
    group: "",
    description: "",
    price: "",
    county: "",
    subcounty: "",
    location: "",
    images: [],
  });

  const [subcategories, setSubcategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [counties, setCounties] = useState([]);
  const [subcounties, setSubcounties] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState("");
  const [selectedSubcounty, setSelectedSubcounty] = useState("");
  
  const userId = JSON.parse(localStorage.getItem("user")).id;

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await fetch("https://farmerr-dgb1.onrender.com/subcategories.json");
        const data = await response.json();
        setSubcategories(data);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    fetchSubcategories();
  }, []);

  const fetchGroups = async (selectedSubcategorySlug) => {
    try {
      const response = await fetch(`https://farmerr-dgb1.onrender.com/subcategories/${selectedSubcategorySlug}/groups.json`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setGroups(data);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  useEffect(() => {
    const fetchCounties = async () => {
      try {
        const response = await fetch("https://farmerr-dgb1.onrender.com/counties");
        const data = await response.json();
        setCounties(data);
      } catch (error) {
        console.error("Error fetching counties:", error);
      }
    };

    fetchCounties();
  }, []);

  const fetchSubcounties = async (selectedCountySlug) => {
    try {
      const response = await fetch(`https://farmerr-dgb1.onrender.com/counties/${selectedCountySlug}/subcounties`);
      const data = await response.json();
      setSubcounties(data);
    } catch (error) {
      console.error("Error fetching subcounties:", error);
    }
  };

  const handleCountyChange = (e) => {
    const selectedCountySlug = e.target.value;
    setSelectedCounty(selectedCountySlug);
    setProductData({ ...productData, county: selectedCountySlug });
    fetchSubcounties(selectedCountySlug);
  };

  const handleSubcountyChange = (e) => {
    const selectedSubcountySlug = e.target.value;
    setSelectedSubcounty(selectedSubcountySlug);
    setProductData({ ...productData, subcounty: selectedSubcountySlug });
  };

  const handleSubcategoryChange = (e) => {
    const selectedSubcategory = e.target.value;
    setProductData({ ...productData, subcategory: selectedSubcategory, group: "" });
    fetchGroups(selectedSubcategory);
  };

  const handleGroupChange = (e) => {
    const selectedGroup = e.target.value;
    setProductData({ ...productData, group: selectedGroup });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "location" && value.length > 25) {
      alert("Location must be 25 characters or less.");
      return;
    }

    setProductData({ ...productData, [name]: value });
  };

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    const totalImages = productData.images.length + files.length;

    if (totalImages > 5) {
      alert("You can only upload a maximum of 5 images.");
      return;
    }

    try {
      const compressedFilesPromises = files.slice(0, 5 - productData.images.length).map(async (file) => {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        return await imageCompression(file, options);
      });

      const compressedFiles = await Promise.all(compressedFilesPromises);
      const updatedImages = [...productData.images, ...compressedFiles];
      setProductData({ ...productData, images: updatedImages });
    } catch (error) {
      console.error("Error compressing images:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (productData.images.length === 0) {
      console.error("No images selected");
      setSubmitting(false);
      return;
    }

    if (productData.location.length > 29) {
      alert("Location must not exceed 29 characters.");
      setSubmitting(false);
      return;
    }

    if (selectedCounty && selectedSubcounty && productData.subcategory && productData.group) {
      const selectedCountyObj = counties.find((county) => county.slug === selectedCounty);
      const selectedSubcountyObj = subcounties.find((subcounty) => subcounty.slug === selectedSubcounty);

      if (selectedCountyObj && selectedSubcountyObj) {
        const formData = new FormData();
        formData.append("product[name]", productData.name);
        formData.append("product[price]", parseInt(productData.price));
        formData.append("product[description]", productData.description);
        formData.append("product[subcategory_id]", productData.subcategory);
        formData.append("product[group_id]", productData.group);
        formData.append("product[user_id]", userId);
        formData.append("county_slug", selectedCounty);
        formData.append("sub_county_slug", selectedSubcounty);
        formData.append("product[location]", productData.location);

        productData.images.forEach((image) => {
          formData.append("product[images][]", image, image.name);
        });

        try {
          const response = await fetch(`https://farmerr-dgb1.onrender.com/subcategories/${productData.subcategory}/groups/${productData.group}/products`, {
            method: "POST",
            body: formData,
          });

          if (response.ok) {
            const data = await response.json();
            console.log("Server Response:", data);
            navigate("/sellershop");
          } else {
            console.error("Add Product error:", response.status);
          }
        } catch (error) {
          console.error("Add Product error:", error);
        } finally {
          setSubmitting(false);
        }
      } else {
        console.error("Invalid county or subcounty");
      }
    } else {
      console.error("Invalid form data");
      setSubmitting(false);
    }
  };

  return (
    <div className="bg-yellow-300 h-screen p-4">
      <h2>Add Product</h2>
      <form onSubmit={handleSubmit}>
        <select
          name="subcategory"
          value={productData.subcategory}
          onChange={handleSubcategoryChange}
          required
          className="p-2 border border-gray-300 rounded-md"
        >
          <option value="">Select Subcategory</option>
          {subcategories.map((subcategory) => (
            <option key={subcategory.slug} value={subcategory.slug}>
              {subcategory.name}
            </option>
          ))}
        </select>
        <select
          name="group"
          value={productData.group}
          onChange={handleGroupChange}
          required
          className="p-2 border border-gray-300 rounded-md ml-4 mt-3 mb-1"
        >
          <option value="">Select Group</option>
          {groups.map((group) => (
            <option key={group.slug} value={group.slug}>
              {group.name}
            </option>
          ))}
        </select>

        <input
          type="text"
          name="name"
          placeholder="Prod Name e.g  green apples"
          value={productData.name}
          onChange={handleInputChange}
          required
          className="p-2 border border-gray-300 rounded-md mb-1"
        />

        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
          <textarea
            name="description"
            placeholder="Describe your product i.e amount, age, and availability period"
            value={productData.description}
            onChange={handleInputChange}
            required
            className="p-2 border border-gray-300 rounded-md"
            rows="4"
          ></textarea>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <label htmlFor="price" className="mr-2">Ksh</label>
          <input
            type="number"
            name="price"
            placeholder="Price in Ksh"
            value={productData.price}
            onChange={handleInputChange}
            required
            className="p-2 border border-gray-300 rounded-md"
          />
        </div>

        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <label htmlFor="county" className="mr-2">County</label>
          <select
            name="county"
            value={selectedCounty}
            onChange={handleCountyChange}
            required
            className="p-2 border border-gray-300 rounded-md"
          >
            <option value="">Select County</option>
            {counties.map((county) => (
              <option key={county.slug} value={county.slug}>
                {county.name}
              </option>
            ))}
          </select>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <label htmlFor="subcounty" className="mr-2">Subcounty</label>
          <select
            name="subcounty"
            value={selectedSubcounty}
            onChange={handleSubcountyChange}
            required
            className="p-2 border border-gray-300 rounded-md"
          >
            <option value="">Select Subcounty</option>
            {subcounties.map((subcounty) => (
              <option key={subcounty.slug} value={subcounty.slug}>
                {subcounty.name}
              </option>
            ))}
          </select>
        </div>

        <input
          type="text"
          name="location"
          placeholder="Location e.g. Nairobi"
          value={productData.location}
          onChange={handleInputChange}
          required
          className="p-2 border border-gray-300 rounded-md"
        />

        <input
          type="file"
          multiple
          onChange={handleImageChange}
          className="p-2 border border-gray-300 rounded-md mt-2"
        />

        <button
          type="submit"
          disabled={submitting}
          className="p-2 bg-green-500 text-white rounded-md mt-4"
        >
          {submitting ? "Submitting..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default AddProduct;











