

//Group frontend
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function Groups() {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subcategoryName, setSubcategoryName] = useState(''); // Define state for subcategory name
  const { subcategorySlug } = useParams();

  useEffect(() => {
    if (subcategorySlug && subcategorySlug !== "undefined") {
      // Fetch subcategory details to get the name
      const subcategoryUrl = `https://farmerr-dgb1.onrender.com/subcategories/${subcategorySlug}`;
      fetch(subcategoryUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then(data => {
          // Assuming 'data' contains a 'name' field
          setSubcategoryName(data.name); // Store the subcategory name
        })
        .catch(error => {
          console.error("Fetch error:", error);
          setError(error);
        });

      // Continue fetching groups as before
      const url = `https://farmerr-dgb1.onrender.com/subcategories/${subcategorySlug}/groups.json`;
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then(data => {
          const sortedGroups = data.sort((a, b) => a.slug.localeCompare(b.slug));
          setGroups(sortedGroups);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Fetch error:", error.message);
          setError(error);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setError(new Error("Subcategory slug is undefined"));
    }
  }, [subcategorySlug]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!Array.isArray(groups) || groups.length === 0) {
    return <div>None available yet</div>;
  }

  return (
    <div className="bghy">
      <Helmet>
        <title>Farmwise - Select groups</title>
        <meta name="description" content="Browse farm produce, inputs and services" />
       </Helmet>
      <div className="bg-green-800 h-10">
        {/* Use subcategoryName from state here */}
    <h1 className="text-white text-center sm:text-left">Groups for Category: {subcategoryName}</h1>
    </div>
    <div className="grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 justify-center p-2">
      {groups.map((group) => (
        <Link
          key={group.id}
          to={`/subcategories/${subcategorySlug}/groups/${group.slug}/products`}
          className="m-1 bg-yellow-300 p-1 rounded-md shadow-lg flex flex-col items-center justify-center"
        >
          <div className="w-24 h-24 flex items-center justify-center overflow-hidden"> {/* Adjusted size */}
            {group.image_url && (
              <img
                src={group.image_url}
                alt={group.name}
                className="w-full h-full object-cover" // Adjust object-fit as needed
              />
            )}
          </div>
          <h2 className="text-xs font-semibold text-sm break-words text-center">{group.name}</h2>
        </Link>
      ))}
    </div>
  </div>
  );
}

export default Groups;



