



// searchbar
import React from 'react';
//  { useState } from 'react';
import { Link } from 'react-router-dom';

function Searchbar() {
  const userDataString = localStorage.getItem('user');
  const isLoggedIn = !!userDataString;
  // const [searchQuery, setSearchQuery] = useState('');
  // const [searchResults, setSearchResults] = useState([]);

  // const handleSearch = async () => {
  //   try {
  //     const response = await fetch(`http://localhost:3000/products/search_by_group?group_slug=${searchQuery}`);
  //     const data = await response.json();
  //     setSearchResults(data);
  //   } catch (error) {
  //     console.error('Error searching products by group:', error);
  //   }
  // };

  return (
    <div>
    {/* <div className="flex flex-col md:flex-row gap-4"> */}
      {/* <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search for products..."
      />
      <button
        type="button"
        onClick={handleSearch}
        className="bg-green-500 text-white rounded-r px-2 md:px-3 py-0 md:py-1"
      >
        Search
      </button>

    {/* Display search results 
{Array.isArray(searchResults) ? (
  searchResults.map((product) => (
    <div key={product.id}>
      {/* Display product information 
      <p>{product.name}</p>
      {/* Add other product details 
    </div>
  ))
) : (
  <p>No results found</p>
)} */}

      {/* Move the following outside the search results */}
      {isLoggedIn ? (
        <Link to="/sellershop">
          <button 
            type="button"
            className="bg-green-500 text-white rounded px-4 md:px-3 py-1 md:py-2 mb-4"
          >
            Dashboard
          </button>
        </Link>
      ) : (
        <Link to="/login">
          <button 
            type="button"
            className="bg-green-500 text-white rounded px-4 md:px-3 py-1 md:py-2 mb-4"
          >
            Be A Seller
          </button>
        </Link>
      )}
    </div>
  );
}

export default Searchbar;


