

import React from "react";
import Sidebar from "./Sidebar";


function Pendingshops() {
    return (

      <div className=" flex">

         <Sidebar/>
      
      <div className=" bg-green-600 flex items-center">
           hhh
      </div>
      </div>
    );
  }
  ///////
  export default Pendingshops;
  
