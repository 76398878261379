
//code

import React from "react";
import { Helmet } from 'react-helmet';
import SubcategoryPage from "../pages/SubcategoryPage";

function Bodyy() {
  return (
    <>
      <Helmet>
        <title>Farmwise - Browse Categories</title>
        <meta name="description" content="Browse farm produce, inputs and services" />
      </Helmet>
      <SubcategoryPage/>
    </>
  );
}
export default Bodyy;



// url example:Image URL: http://localhost:3000//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--42b4e42d64bbf6ffc203474b68f49e1fbfd1cf61/Screenshot%20from%202023-10-27%2023-09-38.png


