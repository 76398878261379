



// show products
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

function ProductPage() {
  const [products, setProducts] = useState(null);
  const { subcategorySlug, groupSlug } = useParams();
  const [selectedCounty, setSelectedCounty] = useState('');
  const [selectedSubCounty, setSelectedSubCounty] = useState('');
  const [counties, setCounties] = useState([]);
  const [subcounties, setSubcounties] = useState([]);
  // const [location, setLocation] = useState([]);
  const userDataString = localStorage.getItem('user');
  const isLoggedIn = !!userDataString;


  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`https://farmerr-dgb1.onrender.com/subcategories/${subcategorySlug}/groups/${groupSlug}/products.json`);
        const data = await response.json();
        console.log('API Response:', data);
        if (data && Array.isArray(data)) {
          setProducts(data);
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    const fetchCounties = async () => {
      try {
        const response = await fetch("https://farmerr-dgb1.onrender.com/counties");
        const data = await response.json();
        setCounties(data);
      } catch (error) {
        console.error("Error fetching counties:", error);
      }
    };

    fetchProducts();
    fetchCounties();
  }, [subcategorySlug, groupSlug]);

  const fetchSubcounties = async (selectedCountySlug) => {
    try {
      const response = await fetch(`https://farmerr-dgb1.onrender.com/counties/${selectedCountySlug}/subcounties`);
      const data = await response.json();
      setSubcounties(data);
    } catch (error) {
      console.error("Error fetching subcounties:", error);
    }
  };

  const handleCountyChange = (event) => {
    const selectedCountySlug = event.target.value;
    setSelectedCounty(selectedCountySlug);
    fetchSubcounties(selectedCountySlug);
  };

  const handleSubCountyChange = (event) => {
    setSelectedSubCounty(event.target.value);
  };

  const handleFilter = () => {
    fetch(`https://farmerr-dgb1.onrender.com/subcategories/${subcategorySlug}/groups/${groupSlug}/products.json?county_slug=${selectedCounty}&sub_county_slug=${selectedSubCounty}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data)) {
          setProducts(data);
        }
      })
      .catch((error) => {
        console.error('Error fetching product data:', error);
      });
  };

  if (!products) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-green-800 min-h-screen flex flex-col items-center p-7">
      <div className="w-full max-w-md mx-auto flex flex-col items-start">
        <div className="flex w-full justify-between items-center mb-3">
          <select value={selectedCounty} onChange={handleCountyChange} className="w-50 px-4 py-2 rounded">
            <option value="">Select County</option>
            {counties.map((county) => (
              <option key={county.slug} value={county.slug}>{county.name}</option>
            ))}
          </select>
          <Link to="/">
            <button className="bg-yellow-200 text-black px-3 py-1 rounded">Home</button>
          </Link>
        </div>

        <select value={selectedSubCounty} onChange={handleSubCountyChange} className=" w-50 px-4 py-2 rounded mb-3 mr-20">
          <option value="">Select Subcounty</option>
          {subcounties.map((subcounty) => (
            <option key={subcounty.slug} value={subcounty.slug}>{subcounty.name}</option>
          ))}
        </select>

        <div className="w-full flex justify-between items-center mb-5 ">
          <button className="bg-yellow-300 px-4 py-2 rounded" onClick={handleFilter}>Apply Filters</button>
          {isLoggedIn ? (
            <Link to="/sellershop" className="ml-4">
              <button type="button" className="bg-green-500 text-white rounded px-4 py-2 ml-6">Dashboard</button>
            </Link>
          ) : (
            <Link to="/login" className="ml-4">
              <button type="button" className="bg-green-500 text-white-300 rounded px-4 py-2">+ Sell</button>
            </Link>
          )}
        </div>
      </div>

      <h1 className="text-2xl font-semibold text-white mb-5">Products</h1>
      <div className="grid grid-cols-3 md:grid-cols-3 gap-4 w-full">
        {products.map(product => (
          <Link key={product.id} to={`/subcategories/${subcategorySlug}/groups/${groupSlug}/products/${product.id}`}>
            <div className="bg-yellow-300 rounded-lg overflow-hidden shadow-lg">
              {product.image_urls && product.image_urls.length > 0 && (
                <img src={`https://farmerr-dgb1.onrender.com${product.image_urls[0]}`} alt="Product" className="w-full h-24 object-cover rounded-lg" />
              )}
              <div className="p-2">
                <h1 className="text-md font-semibold mb-1">{product.name}</h1>
                <p>sh. {product.price}</p>
                {product.location && 
                  <p className="text-green-700 flex items-center">
                    <svg className="h-4 w-3 text-blue-500 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ verticalAlign: 'middle' }}>
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
                    </svg>
                    {product.location}
                  </p>
                }
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default ProductPage;


