
// footer
import React from "react";

function Footer() {
  return (
    <div>
      <footer className="w-5/5  m-auto p-10 d . bg-green-800">
        <div className="md:flex grid grid-cols-2 gap justify-between items-start my-10">
        <div className="space-y-5 py-5 border-b flex items-center">
            <img
              src="/pic45.jpeg"
              alt="Farm Logo"
              className="h-10 w-12 mr-3 rounded-md"
              style={{ objectFit: 'cover' }}
            />
            <h1 className="text-yellow-200 text-2xl font-bold font-mono">Farmwise</h1>
          </div>
          <div className="flex flex-col space-y-2 mt-9">
            <h1 className=" text-green-800">Company</h1>
            <h1 className=" text-green-800">Company</h1>
            <h1 className=" text-green-800">Company</h1>


           
          </div>
          <div className="flex flex-col space-y-2 mt-9">
            <h1>Company</h1>
            <a className="text-sm text-white" href="/about">
              About us
            </a>
            {/* <h1>Location</h1>
            <h1>Company</h1> */}
           
          </div>
          <div className="flex flex-col space-y-3">
            <h1 className=" text-lg">Contact</h1>
            <a className="text-sm text-white">
            info@farmwise.co.ke            </a>
            <a className="text-sm text-white">
            +254750840634
            </a>
            <a className="text-sm text-white" href="https://www.instagram.com/farmwise.co.ke/">
              instagram
            </a>
            <a className="text-sm text-white" href="/">
              twitter
            </a>
            {/* <svg class="h-5 w-5 text-blue-500 ml-9"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" /></svg> */}
          </div>
        
        </div>
        <div className="border-t py-2">
          <p>
            copy &copy;2024 <span className="font-bold">Farmwise</span> All
            Rights Reserved
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;

