
// subcategory show
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';


function SubcategoryPage() {
  const [subcategories, setSubcategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subcategoryName, setSubcategoryName] = useState(''); // Added state for subcategory name

  const { categorySlug } = useParams();

  useEffect(() => {
    fetch(`https://farmerr-dgb1.onrender.com/subcategories.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setSubcategories(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [categorySlug]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Site under maintenance, come back after 15 minutes  {error.message}</div>;
  }

  if (subcategories.length === 0) {
    return <div>No subcategories found.</div>;
  }
////
  return (
    <div className="sub">
       <Helmet>
        <title>Farmwise - Browse Categories</title>
        <meta name="description" content="Browse farm produce, inputs and services" />
       </Helmet>

      <h1>Categories</h1>
      <div className="grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-1 justify-center">
        {subcategories.map((subcategory) => (
          <Link
            key={subcategory.id}
            to={`/subcategories/${subcategory.slug}/groups`}
            className="m-2 bg-yellow-300 p-2 rounded-md shadow-lg"
          >
            <div className="text-center">
              <img
                src={subcategory.image_url}
                alt={subcategory.name}
                className="w-40 h-24 object-cover mx-auto mb-2"
              />
              <h2 className="text- font-semibold text-sm break-words ">{subcategory.name}</h2>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default SubcategoryPage;


