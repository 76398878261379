


// add location code

import React, { useState, useEffect } from "react";

function AddLocation() {
  const [counties, setCounties] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState("");
  const [subcounties, setSubcounties] = useState([]);
  const [newCountyName, setNewCountyName] = useState("");
  const [newSubcountyName, setNewSubcountyName] = useState("");
  const [newCountySlug, setNewCountySlug] = useState("");
  const [newSubcountySlug, setNewSubcountySlug] = useState("");

  useEffect(() => {
    const fetchCounties = async () => {
      try {
        const response = await fetch("https://farmerr-dgb1.onrender.com/counties");
        const data = await response.json();
        setCounties(data);
      } catch (error) {
        console.error("Error fetching counties:", error);
      }
    };
    fetchCounties();
  }, []);

  const fetchSubcounties = async (countySlug) => {
    try {
      const response = await fetch(`https://farmerr-dgb1.onrender.com/counties/${countySlug}/subcounties`);
      const data = await response.json();
      setSubcounties(data);
    } catch (error) {
      console.error("Error fetching subcounties:", error);
    }
  };

  const handleCountyChange = (e) => {
    const selectedCountySlug = e.target.value;
    setSelectedCounty(selectedCountySlug);
    fetchSubcounties(selectedCountySlug);
  };

  const handleCountySubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://farmerr-dgb1.onrender.com/counties", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: newCountyName, slug: newCountySlug }),
      });
      const data = await response.json();
      const updatedCounties = [...counties, data];
      setCounties(updatedCounties);
      setNewCountyName("");
      setNewCountySlug("");
    } catch (error) {
      console.error("Error adding county:", error);
    }
  };

  useEffect(() => {
    console.log("Subcounties:", subcounties); // Log the subcounties array
  }, [subcounties]); // Use subcounties as a dependency for the effect
  

  const handleSubcountySubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://farmerr-dgb1.onrender.com/counties/${selectedCounty}/subcounties`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sub_county: { name: newSubcountyName, slug: newSubcountySlug } }),
      });
      const data = await response.json();
      
      // Ensure subcounties is an array before updating
      const updatedSubcounties = Array.isArray(subcounties) ? [...subcounties, data] : [data];
      setSubcounties(updatedSubcounties);
      
      setNewSubcountyName("");
      setNewSubcountySlug("");
    } catch (error) {
      console.error("Error adding subcounty:", error);
    }
  };


  return (
    <div className="bg-green-600 p-4">
      <form onSubmit={handleCountySubmit}>
        <label htmlFor="newCountyName">New County Name:</label>
        <input type="text" value={newCountyName} onChange={(e) => setNewCountyName(e.target.value)} />
        <label htmlFor="newCountySlug">New County Slug:</label>
        <input type="text" value={newCountySlug} onChange={(e) => setNewCountySlug(e.target.value)} />
        <button type="submit">Add County</button>
      </form>
      <form onSubmit={handleSubcountySubmit}>
        <div>
          <label htmlFor="county">Select County:</label>
          <select name="county" value={selectedCounty} onChange={handleCountyChange}>
            <option value="">Select County</option>
            {counties.map((county) => (
              <option key={county.slug} value={county.slug}>
                {county.name}
              </option>
            ))}
          </select>
        </div>
        <label htmlFor="newSubcountyName">New Subcounty Name:</label>
        <input type="text" value={newSubcountyName} onChange={(e) => setNewSubcountyName(e.target.value)} />
        <label htmlFor="newSubcountySlug">New Subcounty Slug:</label>
        <input type="text" value={newSubcountySlug} onChange={(e) => setNewSubcountySlug(e.target.value)} />
        <button type="submit">Add Subcounty</button>
      </form>
      {subcounties.length > 0 && (
        <div>
          <h3>Subcounties:</h3>
          <ul>
          {subcounties.map((sub_county, index) => (
  <li key={`${sub_county.slug}-${index}`}>
    {sub_county.name}
  </li>
))}
          </ul>
        </div>
      )}
    </div>
  );
}
/////
export default AddLocation;


/////  