import React from "react";
// import ProductList from "./ProductList";
// import ProductForm from "./ProductForm";
// import LogoutButton from "./LogoutButton";

function TermsandCond () {
  return (
    <div>


    </div>
  );
};

export default TermsandCond;