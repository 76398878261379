

// Dashboard
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Userproducts from "./User-products";

const Dashboard = () => {
  const [showConfirmLogout, setShowConfirmLogout] = useState(false); // State to manage the visibility of the confirmation button
  const navigate = useNavigate(); // Hook for navigation
  const userDataString = localStorage.getItem("user");

  if (!userDataString) {
    return <div>User data not found...</div>;
  }

  // Function to handle user logout
  const handleLogout = () => {
    localStorage.removeItem("user"); // Remove user data from localStorage
    navigate("/"); // Redirect to the homepage
  };

  try {
    const userData = JSON.parse(userDataString);
    const { name, email, phone_number, id: userId } = userData;

    return (
      <div className="bg-yellow-200 min-h-screen">
        <div className="bg-green-800 p-4 text-center flex justify-between items-center">
          <div>
            <h1 className="text-yellow-200 inline">Welcome {name}</h1>
            <p className="text-yellow-200">Email: {email}</p>
            <p className="text-yellow-200">Phone: {phone_number}</p>
          </div>
          <div>
            {!showConfirmLogout ? (
              <button
                onClick={() => setShowConfirmLogout(true)}
                className="bg-green-500 px-3 py-1 rounded-sm"
              >
                Logout
              </button>
            ) : (
              <button
                onClick={handleLogout}
                className="bg-red-500 px-3 py-1 rounded-sm"
              >
                Confirm Logout
              </button>
            )}
          </div>
        </div>
        <div className="flex justify-between p-4 bg-green-800">
          <Link to="/addproduct">
            <button className="bg-white px-3 py-1 rounded-sm">Add Product</button>
          </Link>
          <Link to="/">
            <button className="bg-yellow-300 px-3 py-0.5 rounded-sm">Home</button>
          </Link>
        </div>
        <div className="text-center p-4">
          <h2>Your Products</h2>
          <Userproducts userId={userId} /> {/* Pass userId to UserProducts */}
        </div>
      </div>
    );
  } catch (error) {
    console.error("Error parsing user data from localStorage:", error);
    return <div>Error loading user data...</div>;
  }
};

export default Dashboard;



















// marktreva12@example.com
//  youyou78






