
// userproddetails
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import useNavigate
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Userproductdetails = () => {
    const [product, setProduct] = useState(null);
    const [error, setError] = useState(null); // Define error state
    const { userId, productId } = useParams(); 
    const navigate = useNavigate(); // Call useNavigate to get the navigate function


    useEffect(() => {
        console.log(`Fetching details for user ID: ${userId}, product ID: ${productId}`);
        const fetchProductDetails = async () => {
            try {
                const response = await fetch(`https://farmerr-dgb1.onrender.com/users/${userId}/products/${productId}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();

                // Assuming data is the product object directly and has an image_urls property
                const updatedImageURLs = data.image_urls.map(url => `https://farmerr-dgb1.onrender.com${url}`);
                setProduct({ ...data, image_urls: updatedImageURLs });
            } catch (error) {
                console.error("Error fetching product details:", error);
                setError('Error fetching product details');
            }
        };

        fetchProductDetails();
    }, [userId,productId]);

    const handleDelete = async () => {
        try {
            const response = await fetch(`https://farmerr-dgb1.onrender.com/products/${productId}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // Handle post-delete actions here, e.g., navigate back to the list of user products
            console.log('Product deleted successfully');
            navigate(`/sellershop`);

        } catch (error) {
            console.error("Error deleting product:", error);
            setError('Error deleting product');
        }
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!product) {
        return <div>Loading...</div>;
    }

    return (
        <div className="bg-yellow-200 text-black p-4">
            <h1 className=" font-bold text-lg">{product.name}</h1>
            <p className="break-words max-w-full">{product.description}</p>
            <p>Price: Ksh {product.price}</p>
            <button onClick={handleDelete} className="bg-red-500 hover:bg-red-600 text-white font-semibold px-4 py-2 rounded mb-4">
                Delete
            </button>
            {product.image_urls && product.image_urls.length > 0 && (
                <Carousel >
                    {product.image_urls.map((imageUrl, index) => (
                    <div key={index}>
                          <img src={imageUrl} alt={`${product.name} - View ${index + 1}`} style={{ maxHeight: '600px' }} />
                  </div>
                ))}
                </Carousel>
            )}
        </div>
    );
};

export default Userproductdetails;



///