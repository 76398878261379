


// AddSubcategory component
import React, { useState } from 'react';

function AddSubcategory() {
  const [subcategoryName, setSubcategoryName] = useState('');
  const [subcategorySlug, setSubcategorySlug] = useState('');
  const [subcategoryImage, setSubcategoryImage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!subcategoryName || !subcategorySlug || !subcategoryImage) {
      console.error('Please fill in all required fields');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('subcategory[name]', subcategoryName);
      formData.append('subcategory[slug]', subcategorySlug);
      formData.append('subcategory[image]', subcategoryImage);

      const response = await fetch(`https://farmerr-dgb1.onrender.com/subcategories`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        console.log('Subcategory created successfully!');
      } else {
        console.error('Error creating subcategory');
      }
    } catch (error) {
      console.error('Error creating subcategory:', error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={subcategoryName}
          onChange={(e) => setSubcategoryName(e.target.value)}
          placeholder="Enter subcategory name"
        />
        <input
          type="text"
          value={subcategorySlug}
          onChange={(e) => setSubcategorySlug(e.target.value)}
          placeholder="Enter subcategory slug"
        />
        <input
          type="file"
          onChange={(e) => setSubcategoryImage(e.target.files[0])}
          accept="image/*"
        />
        <button type="submit">Add Subcategory</button>
      </form>
    </div>
  );
}
///
export default AddSubcategory;






