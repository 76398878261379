

// login
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const LoginShop = () => {
  const navigate = useNavigate();
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const [error, setError] = useState("");  // Add this line to hold error messages

  const handleRegisterClick = () => {
    navigate("/form");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");  // Clear previous errors on a new submit

    try {
      const response = await fetch('https://farmerr-dgb1.onrender.com/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: emailRef.current.value,
          password: passwordRef.current.value,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("user", JSON.stringify(data.user));
        localStorage.setItem("token", data.token);
        navigate("/sellershop");
      } else {
        // When response is not ok, handle errors
        setError("Incorrect email or password");  // Set a generic error message
      }
    } catch (error) {
      console.error("Login error:", error);
      setError("Failed to connect to the service");  // Handle network or other broader errors
    }
  };

  return (
    <div className="bg-yellow-200 text-white h-screen">
      
        <div className=" p-7 text-yellow-200">
          jj
        </div>
        <div className="border border-green-500 ml-2 mr-2 rounded-2xl">
      <h2 className="text-black mb-3 text-3xl font-sans font-bold">LOGIN</h2>
      <form className="bg-green-800 rounded-2xl" onSubmit={handleSubmit}>
        <div className="mb-4" style={{ display: "flex", flexDirection: "column" }}>
          <input 
            type="email"
            placeholder="Email"
            ref={emailRef}
            required
            name="email"
            id="email"
            autoComplete="email"
            style={{ backgroundColor: "green", marginBottom: "10px", padding: "5px" }}
          />
          <input
            type="password"
            placeholder="Password"
            ref={passwordRef}
            required
            name="password"
            id="password"
            autoComplete="current-password"
            style={{ backgroundColor: "green", padding: "5px" }}
          />
        </div>
        <button type='submit' className="bg-green-600 px-4 py-2 rounded hover:bg-green-600">Log In</button>
        {error && <div style={{ color: "red" }}>{error}</div>}
        <h3 className="mb-3 mt-3">Don't have an account? register below</h3>
        <button className=" bg-green-700 p-2 rounded mt-6 mb-2" onClick={handleRegisterClick}>Register</button>
      </form>
      </div>
    </div>
  );
};

export default LoginShop;





























// # name: Deploy website on push

// # on:
// #    push:
// #     branches:
// #        - master
// # jobs:
// #     deploy_job:
// #       runs-on: ubuntu-latest
// #       name: deploy
// #       steps:
// #         - name: Checkout
// #           uses: actions/checkout@v2
       
// #         - name: Deploy file
// #           uses: wlixcc/SFTP-Deploy-Action@v1.2.4
// #           with:
// #             username: farmwise
// #             password: 6q@;664JzSShxJ
// #             server: 141.95.3.58
// #             port: 1624
// #             remote_path: '/home/farmwise/public_html'
// #             sftp_only: true
// #           env:
// #               DEBUG: true




// # name: Deploy website on push

// # on:
// #   push:
// #     branches:
// #       - master

// # jobs:
// #   web-deploy:
// #     name: Deploy
// #     runs-on: ubuntu-latest
// #     steps:
// #       - name: 🚚 Get latest code
// #         uses: actions/checkout@v3
  
// #       - name: Use Node.js 16
// #         uses: actions/setup-node@v2
// #         with:
// #           node-version: '16'
        
// #       - name: 🔨 Build Project
// #         run: |
// #           npm install
// #           npm run build
      
// #       - name: 📂 Sync files
// #         uses: SamKirkland/FTP-Deploy-Action@v4.3.4
// #         with:
// #           username: farmwise
// #           password: 6q@;664JzSShxJ
// #           server: 141.95.3.58
// #           port: 1624
// #           remote_path: '/home/farmwise/public_html'
// #           sftp_only: true
// #           # protocol: ${{ secrets.GIT_CPANEL_REACT_PROTOCOL }}
// #           local-dir: ./build/








// # name: Deploy website on push

// # on:
// #   push:
// #     branches:
// #       - master

// # jobs:
// #   web-deploy:
// #     name: Deploy
// #     runs-on: ubuntu-latest
// #     steps:
// #       - name: 🚚 Get latest code
// #         uses: actions/checkout@v3

// #       - name: Use Node.js 16
// #         uses: actions/setup-node@v2
// #         with:
// #           node-version: '16'
        
// #       - name: 🔨 Build Project
// #         run: |
// #           npm install
// #           npm run build
      
// #       - name: Create SSH directory
// #         run: mkdir -p $HOME/.ssh    

// #       # - name: Debug SSH
// #       #   run: |
// #       #     echo "$SSH_PRIVATE_KEY" > /home/farmwise/.ssh/deploy_key
// #       #     chmod 600 /home/farmwise/.ssh/deploy_key
// #       #     ssh-add /home/farmwise/.ssh/deploy_key
// #       #     ssh -v -p 1624 -i /home/farmwise/.ssh/deploy_key farmwise@141.95.3.58

// #       - name: Use SSH key
// #         uses: webfactory/ssh-agent@v0.5.0
// #         with:
// #           ssh-private-key: ${{ secrets.SSH_PRIVATE_KEY }}
    
// #       - name: Sync Files using SFTP
// #         uses: easingthemes/ssh-deploy@v2.1.5
// #         with:
// #           REMOTE_HOST: 141.95.3.58
// #           REMOTE_USER: farmwise
// #           REMOTE_PORT: 1624   


//           # server: "141.95.3.58"  # Change host to SFTP
//           # username: "farmwise"
//           # password: "6q@;664JzSShxJ"
//           # port: 1624
//           # local-dir: "./build/"
//           # protocol: "ftps"  # Use SFTP instead of FTPS
//           # passive: true