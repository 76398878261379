import React from "react";
import { Link } from "react-router-dom";

function About (){
    return (
      <>
    <div className=" bg-yellow-300 h-screen">
    {/* <div className=" bg-yellow-300 mb-4 mt-6"> 
      
      </div> */}
      <div className=" bg-yellow-300 mb-4"> 
      <Link to="/">
            <button className="bg-green-700 text-yellow-300 px-3 py-1 rounded mt-4">Home</button>
          </Link>
      </div>
      <div className="w-screen h-[6cm] bg-green-500 text bg-center ">
        {/* Your content here */}
        <p className="text-3xl font-bold"> About Farmwise</p>
        <p>Farmwise.co.ke is a peer-to-peer platform whereby farmers, vendors, consumers, and agricultural manufacturing companies can exchange goods and services.</p>
        <p> At farmwise we sought after providing a digital platform for access of farm goods and markets at a click of a button</p>
      </div>
      <div className="w-screen h-[5cm] bg-yellow-200 text bg-center">
        {/* Your content here */}
        <p className="text-3xl font-bold"> Mission</p>
        <p className=" font-mono">Farmwise aims to increase efficiency in fields and farms, grow the market for the sale of goods and services, and increase opportunities in the agricultural sector</p>
      </div>
      <div className="w-screen h-fulltext bg-center">
        {/* Your content here */}
        <p className="text-3xl font-bold">Founders</p>
        <div className="flex flex-col md:flex-row">
        <div className="border border-black p-4">
            <p>Alex Mungai</p>
            <p>+254748123478</p>
            <p>alexmungaiwaweru4@gmail.com</p>
          </div>
          <div className="border border-black p-4 mb-4 md:mb-0 md:mr-4 mt-8">
            <p>Mark Trevor Njogu</p>
            <p>+254795156246</p>
            <p>marktreva92@gmail.com</p>
          </div>
          
        </div>
      </div> 
    {/* <div className=" w-screen h-[3cm] bg-green-900 text bg-center">
      {/* Your content here 
      <p className=""> Advert</p>
    </div> */}
    </div>
    </>
    );
  }
  
  export default About;
 



  