

// register
import React, { useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

function Form() {
  const navigate = useNavigate(); // Initialize navigate function
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  // const locationRef = useRef()

  const passwordRef = useRef();
  const passwordConfirmRef = useRef();

  const [error, setError] = useState(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => setIsPasswordVisible(!isPasswordVisible);
  const toggleConfirmPasswordVisibility = () => setIsConfirmPasswordVisible(!isConfirmPasswordVisible);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      user: {
        name: nameRef.current.value,
        email: emailRef.current.value,
        phone_number: phoneRef.current.value,
        password: passwordRef.current.value,
        password_confirmation: passwordConfirmRef.current.value
      }
    };
  
    try {
      const res = await axios.post("https://farmerr-dgb1.onrender.com/register", formData);
      console.log('Response from server:', res.data);
      
      // Assuming the API returns user data and a token on successful registration
      localStorage.setItem("user", JSON.stringify(res.data.user));  // Adjust according to actual response
      localStorage.setItem("token", res.data.token);                // Adjust according to actual response
      
      navigate('/sellershop'); // Redirect to the dashboard page after successful registration
    } catch (error) {
      console.error('Error from server:', error);
      if (error.response) {
        setError(error.response.data.error);
      }
    }
  };
  // console.log(formData)

  return (
    <div className="bg-yellow-300 flex justify-center items-center min-h-screen">
      <img src={process.env.PUBLIC_URL + '/pic45.jpeg'} alt="Product" className="w-16 h-16 mb-4 mr-6" />

      <form className=' mr-2' onSubmit={(e) => handleSubmit(e)}>

      <input 
      className='w-full p-2 rounded mb-7 mt-7'
      type="text"
      ref={nameRef}
      placeholder='Name:'
      name="username" // Specify the purpose more clearly
      autoComplete="name" // Standard autocomplete token for full name
    />

    <input 
      className='w-full p-2 rounded mb-6'
      type="email" // Make sure to use the correct type
      ref={emailRef}
      placeholder='Email:'
      name="email"
      autoComplete="email"
    />

    <input 
      className='w-full p-2 rounded mb-7'
      type="tel" // Use "tel" type for phone inputs
      ref={phoneRef}
      placeholder='Phone:'
      name="phone"
      autoComplete="tel"
    />
        {/* <input className='w-full p-2 rounded mb-8' type="text" ref={locationRef} placeholder='Location:' /> */}

        <div className="relative">
          <input
            ref={passwordRef}
            type={isPasswordVisible ? "text" : "password"}
            placeholder="Password"
            className="w-full p-2 rounded mb-4"
          />
           <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 right-0 pr-3 flex items-center"
          >
            {isPasswordVisible ? (
              // Eye Closed Icon in Green
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#10B981"> {/* Green color #10B981 */}
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-6 0-10-8-10-8s4-8 10-8c6 0 10 8 10 8s-4 8-10 8a10.05 10.05 0 01-1.875-.175M8.175 8.175a4 4 0 105.65 5.65" />
              </svg>
            ) : (
              // Eye Open Icon in Green
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#10B981"> {/* Green color #10B981 */}
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0zm-3-7c-4.418 0-8 4-8 7s3.582 7 8 7 8-4 8-7-3.582-7-8-7zM2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
              </svg>
            )}
          </button>
        </div>

        <div className="relative">
          <input
            ref={passwordConfirmRef}
            type={isConfirmPasswordVisible ? "text" : "password"}
            placeholder="Confirm Password"
            className="w-full p-2 rounded mb-4"
          />
          <button
            type="button"
            onClick={toggleConfirmPasswordVisibility} // Use the correct function here
            className="absolute inset-y-0 right-0 pr-3 flex items-center"
          >
            {isConfirmPasswordVisible ? ( // Check the correct state here
              // Eye Closed Icon in Green
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#10B981"> {/* Green color #10B981 */}
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-6 0-10-8-10-8s4-8 10-8c6 0 10 8 10 8s-4 8-10 8a10.05 10.05 0 01-1.875-.175M8.175 8.175a4 4 0 105.65 5.65" />
              </svg>
            ) : (
              // Eye Open Icon in Green
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#10B981"> {/* Green color #10B981 */}
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0zm-3-7c-4.418 0-8 4-8 7s3.582 7 8 7 8-4 8-7-3.582-7-8-7zM2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
              </svg>
            )}
          </button>
        </div>

        <button type='submit' className="w-full bg-green-600 text-yellow-50 px-4 py-2 rounded hover:bg-green-700">Submit</button>
        {error && <div className="text-red-500">{error}</div>}
      </form>
    </div>
  );
}

export default Form;


