
// user-prod
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation


const UserProducts = ({ userId }) => {
  const [userProducts, setUserProducts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch user products data...
    const fetchUserProducts = async () => {
      try {
        const response = await fetch(`https://farmerr-dgb1.onrender.com/users/${userId}/products`);
        if (response.ok) {
          const data = await response.json();
          console.log(data); // Log the received data to inspect its structure
          const productsWithCompleteURLs = data.map((product) => {
            if (product.image_urls && product.image_urls.length > 0) {
              const updatedImageURLs = product.image_urls.map((url) => {
                return `https://farmerr-dgb1.onrender.com${url}`; // Add base URL
              });
              return { ...product, image_urls: updatedImageURLs };
            }
            return product;
          });

          setUserProducts(productsWithCompleteURLs || []);
        } else {
          setError('Error fetching user products');
        }
      } catch (error) {
        console.error("Error fetching user products:", error);
        setError('Error fetching user products');
      }
    };

    fetchUserProducts();
  }, [userId]);

  const handleDelete = async (productId) => {
    try {
      const response = await fetch(`https://farmerr-dgb1.onrender.com/products/${productId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        // Filter out the deleted product from the userProducts state
        setUserProducts(userProducts.filter((product) => product.id !== productId));
      } else {
        setError('Error deleting product');
      }
    } catch (error) {
      console.error("Error deleting product:", error);
      setError('Error deleting product');
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="bg-green-600 rounded-2xl">
    <div className="container mx-auto py-8">
      <div className="flex flex-wrap -mx-">
      {userProducts.length > 0 ? userProducts.map((product) => (
      <Link to={`/users/${userId}/products/${product.id}`} key={product.id} className="w-1/2 px-2 mb-4">
      <div className="bg-white shadow-lg rounded-md overflow-hidden">
        {product.image_urls && product.image_urls[0] && (
          <img src={product.image_urls[0]} alt={product.name} className="w-full h-24 object-cover" />
        )}
        <div className="p-4">
          <h3 className="text-xl font-semibold mb-2">{product.name}</h3>
          <p className="text-gray-800 font-semibold">Price: Ksh {product.price}</p>
          </div>
        </div>
      </Link>
        )) : <div className="w-full text-center">No products yet</div>}
      </div>
    </div>
  </div>
);
};

export default UserProducts;



