
//
import React from "react";
import { Helmet } from 'react-helmet';
import Footer from "../components/footer";
import Searchbar from "../components/Searchbar";
import Bodyy from "../components/body";

function Home() {
  return (
    <div className=" bg-green-900">
      <Helmet>
        <title>Farmwise</title>
        <meta name="description" content="Browse farm produce, inputs and services" />
      </Helmet>

        <header className="">
         {/* <Ad/> */}

         <p className=" text-xl text-white mb-5 "> Welcome to Farmwise</p>
         <p className=" text-yellow-200 mb-3"  > buy/sell farm products, services & equipment  </p>

         <Searchbar/>
        </header>
        <body className="">
          {/* <ItemShowcase/> */}
        <Bodyy/>
        </body>
        <Footer/>
      </div>
    );
  }
  //
  export default Home;
  