

import React from 'react';
import './App.css';
import AppRouter from './Router/AppRouter';

function App() {
  return (
    <>

    <div className=' mr-1'>
<div className='App'>
       <AppRouter/>
    </div>

    </div>
</>
  );
}
//////////
export default App;
