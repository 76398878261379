

// prod details page
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function ProductDetailPage() {
  const [product, setProduct] = useState(null);
  // const [phone, setPhoneNumber] = useState(null); // Changed variable name
  const { productId, subcategorySlug, groupSlug } = useParams();

  useEffect(() => {
    fetch(`https://farmerr-dgb1.onrender.com/subcategories/${subcategorySlug}/groups/${groupSlug}/products/${productId}?include=sub_county,location`)
      .then(response => response.json())
      .then(data => {

        console.log('Product data:', data); // Log the data received from the API

        const completeImageURLs = data.image_urls.map(relativeURL => {
          return `https://farmerr-dgb1.onrender.com${relativeURL}`;
        });

        const updatedProduct = { ...data, image_urls: completeImageURLs };
        setProduct(updatedProduct);
        // setPhoneNumber(data.phone);

      })
      .catch(error => {
        console.error('Error fetching product data:', error);
      });
  }, [productId, subcategorySlug, groupSlug]);

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-yellow-300 mb-7 h-screen w-screen">
      <p className=" text-yellow-300">ggggg</p>
      {product.image_urls && product.image_urls.length > 0 && (
        <Carousel showThumbs={false} dynamicHeight={true} autoPlay={false}>
          {product.image_urls.map((imageUrl, index) => (
            <div key={index}>
              <img 
                src={imageUrl} 
                alt={`Slide ${index + 1}`} 
                style={{ width: '95%', maxHeight: '400px' }} // Control image size
              />
            </div>
          ))}
        </Carousel>
      )}

      <div className="text-center ml-2 mr-2 mt-2">
        <div className="flex justify-between items-center w-full">
          <h1 className="text-xl font-bold text-start">{product.name}</h1>
          <p className="font-semibold text-green-700">Price: Ksh {product.price}</p>
        </div>
        <p className="break-words text-start ml-2">{product.description}</p>
        
        <p className="text-green-600">Shop Phone Number: 
        <a href={`tel:${product.user_phone}`} className="text-blue-500 hover:text-blue-700">
          {product.user_phone}
        </a>
      </p>
      </div>
    </div>
  );
}

export default ProductDetailPage;

















//http://localhost:3000/categories/animal-produce-input/subcategories/aqua/groups/juy/products/2.json